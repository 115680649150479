import VueRouter from 'vue-router'
import Vue from 'vue/dist/vue.js'
import Axios from 'axios'

const HomeView = () => import('views/home/home.vue')
const LegalDepartmentView = () => import('views/legal_department/legal_department.vue')
const CSuiteView = () => import('views/c_suite/c_suite.vue')
const ForLawyersView = () => import('views/for_lawyers/for_lawyers.vue')
const LegalDepartmentPageThankyou = () => import('views/legal_department/thankyou.vue')
const LegalServicePageThankyou = () => import('views/home/contact-lawyer-thankyou.vue')
const SalesRepresentativePageThankyou = () => import('views/home/contact-sales-representative-thankyou.vue')
const ContactView = () => import('views/contact/contact.vue')
const ContactSalesRepresentativeView = () => import('views/home/contact-sales-representative.vue')
const LandingPageView = () => import('views/landing_page/landing_page.vue')
const LandingPageThankyou = () => import('views/landing_page/thankyou.vue')
const OnboardingPageThankyou = () => import('views/onboarding/selfserv/thankyou.vue')
const HighVolumeDocumentReviewView = () => import('views/high_volume_document_review/high_volume_document_review.vue')
const PublicCompanyCLOView = () => import('views/public_company_clo/public_company_clo.vue')
const GettingStartedView = () => import('views/getting-started/getting-started.vue')
const LawyersIndexView = () => import('views/lawyers/index.vue')
const LawyersShowView = () => import('views/lawyers/show.vue')
const ResourcesView = () => import('views/resources/resources.vue')

const ServicesView = () => import('views/services/services.vue')
const AboutView = () => import('views/about/about-us.vue')
const JoinView = () => import('views/join/join-us.vue')
const HowView = () => import('views/how/how.vue')
const TermsView = () => import('views/terms/terms.vue')
const PrivacyPolicyView = () => import('views/privacy_policy/privacy_policy.vue')
const PaymentInstructionsView = () => import('views/payment_instructions/payment_instructions.vue')

const DocumentGeneration = () => import('views/document_generation/index.vue')
const NDAGeneration = () => import('views/document_generation/nda/index.vue')
const APAGeneration = () => import('views/document_generation/apa/index.vue')
const ShareHolderAgreementGeneration = () => import('views/document_generation/share_holder_agreement/index.vue')
const PrivacyPolicyGeneration = () => import('views/document_generation/privacy_policy/index.vue')
const TermsOfUseGeneration = () => import('views/document_generation/terms_of_use/index.vue')
const ContractorAgreementGeneration = () => import('views/document_generation/contractor_agreement/index.vue')
const DeveloperAgreementGeneration = () => import('views/document_generation/developer_agreement/index.vue')
const EmploymentAgreementGeneration = () => import('views/document_generation/employment_agreement/index.vue')
const SharePurchaseAgreementGeneration = () => import('views/document_generation/share_purchase_agreement/index.vue')
const IPTransferAgreementGeneration = () => import('views/document_generation/ip_transfer_agreement/index.vue')

const BdrOnboarding = () => import('views/onboarding/bdr/main_stepper.vue')

const SelfServeOnboarding = () => import('views/onboarding/selfserv/index.vue')

const LawyersEligibilityCheck = () => import('views/for_lawyers/main_stepper.vue')

const LawyerOnboarding = () => import('views/onboarding/lawyer/main_stepper.vue')

const SarahAdler0402 = () => import('views/resources/sarah-adler-0402.vue')
const SarahAdler0409 = () => import('views/resources/sarah-adler-0409.vue')
const SarahAdler0416 = () => import('views/resources/sarah-adler-0416.vue')
const SarahAdler0424 = () => import('views/resources/sarah-adler-0424.vue')
const SarahAdler0430 = () => import('views/resources/sarah-adler-0430.vue')
const SarahAdler0507 = () => import('views/resources/sarah-adler-0507.vue')
const SarahAdler0514 = () => import('views/resources/sarah-adler-0514.vue')
const SarahAdler0525 = () => import('views/resources/sarah-adler-0525.vue')
const SarahAdler0528 = () => import('views/resources/sarah-adler-0528.vue')
const SarahAdler0604 = () => import('views/resources/sarah-adler-0604.vue')
const SarahAdler0611 = () => import('views/resources/sarah-adler-0611.vue')
const SarahAdler0618 = () => import('views/resources/sarah-adler-0618.vue')
const SarahAdler0625 = () => import('views/resources/sarah-adler-0625.vue')
const SteveMonk0518 = () => import('views/resources/steve-monk-0518.vue')
const SteveMonk0525 = () => import('views/resources/steve-monk-0525.vue')
const SteveMonk0530 = () => import('views/resources/steve-monk-0530.vue')
const SteveMonk0601 = () => import('views/resources/steve-monk-0601.vue')
const SteveMonk0608 = () => import('views/resources/steve-monk-0608.vue')
const SteveMonk0615 = () => import('views/resources/steve-monk-0615.vue')
const SteveMonk0622 = () => import('views/resources/steve-monk-0622.vue')
const SteveMonk0629 = () => import('views/resources/steve-monk-0629.vue')
const MattBelbeck0627 = () => import('views/resources/matt-belbeck-0627.vue')

const RegistrationNewVue = () => import('views/registrations/new.vue')
const SessionsNewVue = () => import('views/sessions/new.vue')
const PasswordsNewVue = () => import('views/passwords/new.vue')
const PasswordsEditVue = () => import('views/passwords/edit.vue')

const MyAccountDashboard = () => import('views/my_account/dashboard.vue')
const MyAccountAccountProfile = () => import('views/my_account/account/profile.vue')
const MyAccountChangeCompany = () => import('views/my_account/client/change_company.vue')
const MyAccountSelectService = () => import('views/my_account/client/select_service.vue')
const MyAccountChangeRole = () => import('views/my_account/change_role.vue')
const MyAccountBookingsIndex = () => import('views/my_account/client/bookings/index.vue')
const MyAccountInvoicesIndex = () => import('views/my_account/client/invoices/index.vue')
const MyAccountStatementsIndex = () => import('views/my_account/client/statements/index.vue')
const MyAccountPaymentDetails = () => import('views/my_account/client/payment_details/payment_details.vue')
const MyAccountSolePractitionerPaymentDetails = () => import('views/my_account/client/payment_details/sole_practitioner_payment_details.vue')
const MyAccountFindLawyers = () => import('views/my_account/client/lawyers/search.vue')
const MyAccountLawyersTimeIndex = () => import('views/my_account/client/casefiles/index.vue')
const MyAccountRetainersIndex = () => import('views/my_account/client/retainers/index.vue')
const MyAccountTestimonialsIndex = () => import('views/my_account/client/testimonials/index.vue')
const MyAccountTestimonialsCreate = () => import('views/my_account/client/testimonials/create.vue')
const MyAccountEngagementCreate = () => import('views/my_account/client/my_engagement/create.vue')
const MyAccountDocumentIndex = () => import('views/my_account/documents/index.vue')

const MyAccountCasefilesIndex = () => import('views/my_account/lawyer/casefiles/index.vue')
const MyAccountExpensesIndex = () => import('views/my_account/lawyer/expenses/index.vue')
const MyAccountClientsIndex = () => import('views/my_account/clients/index.vue')
const MyAccountMyReportsIndex = () => import('views/my_account/bdr/my_reports/index.vue')
const MyAccountPaymentsIndex = () => import('views/my_account/lawyer/payments/index.vue')
const MyAccountLawyerBookingsIndex = () => import('views/my_account/lawyer/bookings/index.vue')
const MyAccountLawyerTestimonialsIndex = () => import('views/my_account/lawyer/testimonials/index.vue')

const MyAccountLeadsIndex = () => import('views/my_account/bdr/leads/index.vue')
const MyAccountSolePractitionerClientManagement = () =>
  import('views/my_account/sole_practitioner/client_management/index.vue')
const MyAccountSolePractitionerProspect = () => import('views/my_account/sole_practitioner/my_prospects/index.vue')
const MyAccountSolePractitionerTeam = () => import('views/my_account/sole_practitioner/my_team/index.vue')
const MyAccountSolePractitionerRevenue = () => import('views/my_account/sole_practitioner/my_revenue/index.vue')
const MyAccountSolePractitionerInbox = () => import('views/my_account/sole_practitioner/my_inbox/index.vue')
const MyAccountSolePractitionerReports = () => import('views/my_account/sole_practitioner/my_reports/index.vue')

const MyAccountSolePractitionerSettings = () => import('views/my_account/sole_practitioner/settings/index.vue')

const MyAccountSolePractitionerOnboarding = () =>
  import('views/my_account/sole_practitioner/onboarding/main_stepper.vue')

const MyAccountOpportunitiesIndex = () => import('views/my_account/bdr/opportunities/index.vue')
const MyAccountMeetingStatsIndex = () => import('views/my_account/bdr/stats/meetings/index.vue')
const MyAccountActualRevenueStatsIndex = () => import('views/my_account/bdr/stats/actual_revenue/index.vue')
const MyAccountProjectedRevenueStatsIndex = () => import('views/my_account/bdr/stats/projected_revenue/index.vue')
const MyAccountClientsRevenueStatsIndex = () => import('views/my_account/bdr/stats/clients_revenue/index.vue')
const MyAccountBDRRevenueStatsIndex = () => import('views/my_account/bdr/stats/revenue_by_bdr/index.vue')
const MyAccountClientsRevenueContributionStatsIndex = () => import('views/my_account/bdr/stats/revenue_contribution/index.vue')
const MyAccountClientAdminProfile = () => import('views/my_account/client_admin/current_admin/profile.vue')
const MyAccountClientAdminClientsIndex = () => import('views/my_account/client_admin/clients/index.vue')
const MyAccountClientAdminClientDashboard = () =>
  import('views/my_account/client_admin/clients/selected_client/dashboard.vue')
const MyAccountClientAdminClientProfile = () =>
  import('views/my_account/client_admin/clients/selected_client/profile.vue')
const MyAccountClientAdminClientEngagements = () =>
  import('views/my_account/client_admin/clients/selected_client/engagements.vue')
const MyAccountClientAdminClientDocuments = () =>
  import('views/my_account/client_admin/clients/selected_client/documents.vue')
const MyAccountClientAdminClientLawyersTime = () =>
  import('views/my_account/client_admin/clients/selected_client/lawyers_time.vue')
const MyAccountClientAdminClientInvoices = () =>
  import('views/my_account/client_admin/clients/selected_client/invoices.vue')
const MyAccountClientAdminClientStatements = () =>
  import('views/my_account/client_admin/clients/selected_client/statements.vue')

const MyAccountClausehoundDocumentTypesIndex = () =>
  import('views/my_account/application_admin/clausehound_document_types/index.vue')
const MyAccountApplicationSettingsIndex = () =>
  import('views/my_account/application_admin/application_settings/index.vue')
const MyAccountFeeEarnersIndex = () => import('views/my_account/application_admin/fee_earners/index.vue')
const MyAccountHowDidYouHearAboutUsIndex = () =>
  import('views/my_account/application_admin/how_did_you_hear_about_us/index.vue')
const MyAccountIndustriesIndex = () => import('views/my_account/application_admin/industries/index.vue')
const MyAccountLegalServicesIndex = () => import('views/my_account/application_admin/legal_services/index.vue')
const MyAccountLegislationsIndex = () => import('views/my_account/application_admin/legislations/index.vue')

const MyAccountMyLawyersIndex = () => import('views/my_account/lawyers_admin/my_lawyers/index.vue')
const MyAccountJobInquiryLawyersIndex = () => import('views/my_account/lawyers_admin/job_inquiry_lawyers/index.vue')

const MyAccountLawyersRevenueStatsIndex = () => import('views/my_account/bdr/stats/lawyers_revenue/index.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/my_account/stats/lawyers_revenue',
    component: MyAccountLawyersRevenueStatsIndex,
    meta: { requiresAuth: true },
  },
  { path: '/', name: 'home', component: HomeView, props: true },
  { path: '/legal-department', component: LegalDepartmentView },
  { path: '/c-suite', component: CSuiteView },
  { path: '/for-lawyers', component: ForLawyersView },
  {
    path: '/lawyer-consultation-thankyou',
    component: LegalDepartmentPageThankyou,
  },
  { path: '/contact', component: ContactView },
  {
    path: '/contact-sales-representative',
    name: 'sales-representative-contact',
    component: ContactSalesRepresentativeView,
    props: true,
  },
  {
    path: '/sales-representative-consultation-thankyou',
    component: SalesRepresentativePageThankyou,
  },
  {
    path: '/legal-service-consultation-thankyou',
    component: LegalServicePageThankyou,
  },
  { path: '/expertconsultation', component: LandingPageView },
  { path: '/expertconsultation-thankyou', component: LandingPageThankyou },
  {
    path: '/high-volume-document-review',
    component: HighVolumeDocumentReviewView,
  },
  { path: '/public-company-clo', component: PublicCompanyCLOView },
  { path: '/getting-started', component: GettingStartedView },
  { path: '/lawyers', component: LawyersIndexView },
  { path: '/lawyers/:slug', component: LawyersShowView },
  { path: '/resources', component: ResourcesView },
  { path: '/services', component: ServicesView },
  { path: '/about', component: AboutView },
  { path: '/join', component: JoinView },
  { path: '/how-it-works', component: HowView },
  { path: '/terms', component: TermsView },
  { path: '/privacy-policy', component: PrivacyPolicyView },
  { path: '/payment-instructions', component: PaymentInstructionsView },

  // { 'path': '/document_generation', component: DocumentGeneration },
  {
    path: '/document_generation/basic-nondisclosure-agreement',
    component: NDAGeneration,
  },
  {
    path: '/document_generation/asset-purchase-agreement',
    component: APAGeneration,
  },
  {
    path: '/document_generation/shareholder-agreement',
    component: ShareHolderAgreementGeneration,
  },
  {
    path: '/document_generation/privacy-policy',
    component: PrivacyPolicyGeneration,
  },
  {
    path: '/document_generation/terms-of-use',
    component: TermsOfUseGeneration,
  },
  {
    path: '/document_generation/contractor-agreement',
    component: ContractorAgreementGeneration,
  },
  {
    path: '/document_generation/developer-agreement',
    component: DeveloperAgreementGeneration,
  },
  {
    path: '/document_generation/employment-agreement',
    component: EmploymentAgreementGeneration,
  },
  {
    path: '/document_generation/share-purchase-agreement',
    component: SharePurchaseAgreementGeneration,
  },
  {
    path: '/document_generation/ip-transfer-agreement',
    component: IPTransferAgreementGeneration,
  },

  { path: '/onboarding/bdr', component: BdrOnboarding },
  { path: '/onboarding/selfserv', component: SelfServeOnboarding },
  {
    path: '/onboarding-thankyou',
    name: 'thankyou',
    props: true,
    component: OnboardingPageThankyou,
  },
  { path: '/lawyers_eligibility_check', component: LawyersEligibilityCheck },
  { path: '/onboarding/lawyer', component: LawyerOnboarding },
  { path: '/resources/sarah-adler-04-02', component: SarahAdler0402 },
  { path: '/resources/sarah-adler-04-09', component: SarahAdler0409 },
  { path: '/resources/sarah-adler-04-16', component: SarahAdler0416 },
  { path: '/resources/sarah-adler-04-24', component: SarahAdler0424 },
  { path: '/resources/sarah-adler-04-30', component: SarahAdler0430 },
  { path: '/resources/sarah-adler-05-07', component: SarahAdler0507 },
  { path: '/resources/sarah-adler-05-14', component: SarahAdler0514 },
  { path: '/resources/steve-monk-05-18', component: SteveMonk0518 },
  { path: '/resources/steve-monk-05-25', component: SteveMonk0525 },
  { path: '/resources/sarah-adler-05-25', component: SarahAdler0525 },
  { path: '/resources/sarah-adler-05-28', component: SarahAdler0528 },
  { path: '/resources/steve-monk-05-30', component: SteveMonk0530 },
  { path: '/resources/steve-monk-06-01', component: SteveMonk0601 },
  { path: '/resources/sarah-adler-06-04', component: SarahAdler0604 },
  { path: '/resources/steve-monk-06-08', component: SteveMonk0608 },
  { path: '/resources/sarah-adler-06-11', component: SarahAdler0611 },
  { path: '/resources/steve-monk-06-15', component: SteveMonk0615 },
  { path: '/resources/sarah-adler-06-18', component: SarahAdler0618 },
  { path: '/resources/steve-monk-06-22', component: SteveMonk0622 },
  { path: '/resources/sarah-adler-06-25', component: SarahAdler0625 },
  { path: '/resources/matt-belbeck-06-27', component: MattBelbeck0627 },
  { path: '/resources/steve-monk-06-29', component: SteveMonk0629 },
  { path: '/sign-up', component: RegistrationNewVue },
  { path: '/sign-in', component: SessionsNewVue, name: 'sign_in' },
  { path: '/new-password', component: PasswordsNewVue },
  { path: '/edit-password', component: PasswordsEditVue },
  {
    path: '/my_account/dashboard',
    name: 'dashboard',
    props: true,
    component: MyAccountDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/account/profile',
    component: MyAccountAccountProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/documents/index',
    component: MyAccountDocumentIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/bookings/index',
    component: MyAccountBookingsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/invoices/index',
    component: MyAccountInvoicesIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/statements/index',
    component: MyAccountStatementsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/payment_details',
    component: MyAccountPaymentDetails,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/sole_practitioner_payment_details',
    component: MyAccountSolePractitionerPaymentDetails,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/lawyers/search',
    component: MyAccountFindLawyers,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/lawyers_time',
    component: MyAccountLawyersTimeIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/retainers/index',
    component: MyAccountRetainersIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/testimonials/index',
    component: MyAccountTestimonialsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/testimonials/create',
    component: MyAccountTestimonialsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/my_engagement/create',
    component: MyAccountEngagementCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/casefiles/index',
    component: MyAccountCasefilesIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/expenses/index',
    component: MyAccountExpensesIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/opportunities',
    component: MyAccountOpportunitiesIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/inquiries',
    component: MyAccountLeadsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_management',
    component: MyAccountSolePractitionerClientManagement,
    name: 'client-management',
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/my_prospects',
    component: MyAccountSolePractitionerProspect,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/my_team',
    component: MyAccountSolePractitionerTeam,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/my_revenue',
    component: MyAccountSolePractitionerRevenue,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/my_inbox',
    component: MyAccountSolePractitionerInbox,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/reports',
    component: MyAccountSolePractitionerReports,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/settings',
    component: MyAccountSolePractitionerSettings,
    meta: { requiresAuth: true },
  },
  { path: '/onboarding/sole_practitioner', component: MyAccountSolePractitionerOnboarding },
  {
    path: '/my_account/lawyers',
    component: MyAccountMyLawyersIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/job_applications',
    component: MyAccountJobInquiryLawyersIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/clausehound_document_types',
    component: MyAccountClausehoundDocumentTypesIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/application_settings',
    component: MyAccountApplicationSettingsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/fee_earners',
    component: MyAccountFeeEarnersIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/how_did_you_hear_about_us',
    component: MyAccountHowDidYouHearAboutUsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/industries',
    component: MyAccountIndustriesIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/legal_services',
    component: MyAccountLegalServicesIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/legislations',
    component: MyAccountLegislationsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/stats/meetings',
    component: MyAccountMeetingStatsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/stats/actual_revenue',
    component: MyAccountActualRevenueStatsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/stats/revenues',
    component: MyAccountClientsRevenueStatsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/stats/bdr_revenue',
    component: MyAccountBDRRevenueStatsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/stats/contributions',
    component: MyAccountClientsRevenueContributionStatsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/stats/projected_revenue',
    component: MyAccountProjectedRevenueStatsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/clients',
    component: MyAccountClientsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/my_reports',
    component: MyAccountMyReportsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/profile',
    component: MyAccountClientAdminProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/clients',
    component: MyAccountClientAdminClientsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/companies/selected_client/client_home',
    name: 'client-dashboard',
    props: true,
    component: MyAccountClientAdminClientDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/companies/selected_client/client_profile',
    name: 'client-profile',
    props: true,
    component: MyAccountClientAdminClientProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/companies/selected_client/client_engagements',
    name: 'client-engagement',
    props: true,
    component: MyAccountClientAdminClientEngagements,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/companies/selected_client/client_documents',
    name: 'client-documents',
    props: true,
    component: MyAccountClientAdminClientDocuments,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/companies/selected_client/client_lawyers_time',
    name: 'client-lawyer-time',
    props: true,
    component: MyAccountClientAdminClientLawyersTime,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/companies/selected_client/client_invoices',
    name: 'client-invoices',
    props: true,
    component: MyAccountClientAdminClientInvoices,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/client_admin/companies/selected_client/client_statements',
    name: 'client-statements',
    props: true,
    component: MyAccountClientAdminClientStatements,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/payments',
    component: MyAccountPaymentsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/bookings',
    component: MyAccountLawyerBookingsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/testimonials',
    component: MyAccountLawyerTestimonialsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/account/change_company',
    component: MyAccountChangeCompany,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/account/select_service',
    name: 'select-service',
    props: true,
    component: MyAccountSelectService,
    meta: { requiresAuth: true },
  },
  {
    path: '/my_account/account/change_role',
    name: 'select-role',
    props: true,
    component: MyAccountChangeRole,
    meta: { requiresAuth: true },
  },
]
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    document.getElementById('app').scrollIntoView()
  },
})

router.beforeEach(function (to, from, next) {
  const ceolawPortal = /localhost|127\.0\.0\.1|ceolawcanada/.test(window.location.href)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // axios.get('/authenticated.json')
    axios.get('/my_account/user.json', { params: { path: to.path } }).then(function (response) {
      var user = response.data
      if (
        to.path === '/sign-up' ||
        to.path === '/sign-in' ||
        to.path === '/reset-password' ||
        to.path === '/edit-password'
      ) {
        if (!ceolawPortal) {
          next('/sign-in')
        } else {
          next('/')
        }
        return
      }
      if (user.redirect_to_home) {
        next('/my_account/dashboard?redirected=true')
      }
      if (
        (from.path === '/onboarding/bdr' || to.path.match(/my_account/)) &&
        user.kyc_wizard_onboarding &&
        !user.kyc_wizard_onboarding.finished &&
        user.is_client &&
        !to.path.match(/change_company/)
      ) {
        next(
          user.sole_practitioner_client
            ? `/onboarding/sole_practitioner?token=${user.kyc_wizard_onboarding.token}&reset_password_token=${user.kyc_wizard_onboarding.password_token}`
            : `/onboarding/bdr?token=${user.kyc_wizard_onboarding.token}&reset_password_token=${user.kyc_wizard_onboarding.password_token}`,
        )
      } else if (
        (from.path === '/onboarding/lawyer' || to.path.match(/my_account/)) &&
        user.lawyer_wizard_onboarding &&
        !user.lawyer_wizard_onboarding.finished &&
        user.is_lawyer &&
        !to.path.match(/change_company/)
      ) {
        next(
          `/onboarding/lawyer?token=${user.lawyer_wizard_onboarding.token}&reset_password_token=${user.lawyer_wizard_onboarding.password_token}`,
        )
      } else if (
        (from.path === '/onboarding/sole_practitioner' || to.path.match(/my_account/)) &&
        user.kyc_wizard_onboarding &&
        !user.kyc_wizard_onboarding.finished &&
        user.is_client &&
        !to.path.match(/change_company/)
      ) {
        next(
          `/onboarding/sole_practitioner?token=${user.kyc_wizard_onboarding.token}&reset_password_token=${user.kyc_wizard_onboarding.password_token}`,
        )
      } else if (
        (from.path === '/onboarding/lawyer' || to.path.match(/my_account/)) &&
        user.lawyer_wizard_onboarding &&
        !user.lawyer_wizard_onboarding.finished &&
        user.is_lawyer &&
        !to.path.match(/change_company/)
      ) {
        next(
          `/onboarding/lawyer?token=${user.lawyer_wizard_onboarding.token}&reset_password_token=${user.lawyer_wizard_onboarding.password_token}`,
        )
      } else {
        next()
      }
    }).catch(function (error) {
      if (error.response.status === 401) {
        if (window.location.hash && window.location.hash == '#statement_page') {
          next('/sign-in#statement_page')
        } else {
          next('/sign-in')
        }
        return
      }
      if (to.path.match(/my_account/)) {
        if (!ceolawPortal) {
          next('/sign-in')
        } else {
          next('/')
        }
        return
      }
      next()
    })
  } else {
    if (!ceolawPortal && to.path === '/') {
      next('/sign-in') // Redirect to /sign-in if the user is trying to access the root
    } else {
      next() // Allow navigation to other routes
    }
  }
})

export default router

const axios = Axios
