<template>
  <div id="vue-footer" class="container-centered">
    <div class="columns">

      <v-layout row wrap class="footer-first-block">

        <v-flex xs12 sm12 md5 class="hidden-xs-only chat">
          <h3 class="text-red">
            Have questions about us or our platform? Let us know.
          </h3>

          <p>
            <router-link :to="'/'" title="Live Chat" v-if="$ceolawPortal" @click.native="openChat">
              <img alt='Chat bot link' class='open-drift-widget' src="../../images/live-chat.png"  width="133" height="35"/>
            </router-link>
          </p>
        </v-flex>

        <v-flex xs12 sm12 md7>
          <div class="footer-links">
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <ul class="collapsed-ul">
                  <li><router-link :to="'/about'" title="About CEO Law">About Us</router-link></li>
                  <li v-if="this.$route.name == 'home'"><a href="javascript:document.getElementById('legal-service-steps').scrollIntoView(true);">For Entrepreneurs</a></li>
                  <li v-else><router-link :to="{ name: 'home', params: { id: 'legal-service-steps' }}" title="For Entrepreneurs">For Entrepreneurs</router-link></li>
                  <li><router-link :to="'/legal-department'" title="Legal Department">For Legal Departments</router-link></li>
                  <li><router-link :to="'/lawyers'" title="Our Lawyers">Our Lawyers</router-link></li>
                  <li><a href='http://blog.ceolawcanada.com/' title='Resources'>Our Blog</a></li>
                  <li><router-link :to="'/contact'" title="Contact Us">Contact Us</router-link></li>
                  <li><router-link :to="'/join'" title="Join CEO Law">Join CEO Law</router-link></li>
                  <li><router-link :to="'/terms'" title="Terms of Use">Terms of Use</router-link></li>
                  <li><router-link :to="'/privacy-policy'" title="Privacy Policy">Privacy Policy</router-link></li>
                </ul>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="footer-second-block">
        <v-flex xs12 sm4 md4 class="hidden-xs-only">
        </v-flex>

        <v-flex xs12 sm8 md7>
          <v-layout row wrap class="footer-legal-block">
            <v-flex xs12 sm4 md4 order-sm2>
              <div class="footer-social-links">
                <a href="https://www.facebook.com/ceolawcanada/" title="Facebook" target="_blank" rel="noopener"><icon name="facebook" scale="1.5"></icon></a>
                <a href="https://x.com/ceolawcanada" title="Twitter" target="_blank" rel="noopener"><icon name="twitter" scale="1.5"></icon></a>
                <a href="https://www.linkedin.com/company/27210721/" title="LinkedIn" target="_blank" rel="noopener"><icon name="linkedin" scale="1.5"></icon></a>
                <a href="https://www.youtube.com/channel/UC14BkLaSuRXWfuSqZ_O4wvQ/videos" title="YouTube" target="_blank" rel="noopener"><icon name="youtube" scale="1.5"></icon></a>
              </div>
            </v-flex>
            <v-flex xs12 sm8 md8 order-sm1>
              <em class="text-red">Copyright &copy; CEO Law. All Rights Reserved.</em>
            </v-flex>
          </v-layout>
        </v-flex>

      </v-layout>

  </div>
 </div>
</template>

<script>
  import 'vue-awesome/icons';
  import Icon from 'vue-awesome/components/Icon';

  export default {
    components: {
      Icon
    },
    methods: {
      openChat() {
        drift.api.toggleChat();
      }
    }
  };
</script>
