<template>
  <v-app>
    <div style="width: 100%" v-if="isLoaded">
      <div class="menu-container">
        <div class="menu-close">
          <div v-show="$vuetify.breakpoint.mdAndUp">
            <router-link
                v-if="!loggedIn()"
                :to="'/sign-in'"
                class="menu-sign-in"
                title="Sign In"
                @click.native="closeMenu"
            >
              Log In
            </router-link>
            <router-link
              v-if="loggedIn()"
              :to="'/'"
              class="menu-sign-in"
              title="Sign Out"
              v-on:click.native="destroySession(globalConfig, $ceolawPortal)"
            >
              Log Out
            </router-link>
          </div>
          <i class="material-icons menu-toggle" @click="toggle">close</i>
        </div>
        <HamburgerMenu />
      </div>
      <div :class="{ insideAccount: insideAccount() }" class="page-container">
        <v-toolbar
            v-if="!insideAccount()"
            id="navbar"
            prominent
            v-bind:class="{ 'navbar-fixed-topNavbar': fixedNavbar() }"
        >
          <v-layout row style="justify-content: space-between" wrap>
            <v-flex class="logo-flex" xs2>
              <router-link v-if="$ceolawPortal" :to="'/'" title="CEO Law">
                <span v-show="$vuetify.breakpoint.xs" class="title">
                  <img id="brand-logo" :src="globalConfig.logo_small_screen" alt="brand logo" />
                </span>
                <span v-show="$vuetify.breakpoint.smAndUp" class="title">
                  <img id="brand-logo" :src="globalConfig.logo_login_page" alt="brand logo" />
                </span>
              </router-link>
              <router-link
                  v-else
                  :to="{ path: '/' }"
                  tag="a"
                  title="CEO Law"
                  @click.native.prevent="navigateToExternalLink"
              >
                <span v-show="$vuetify.breakpoint.xs" class="title">
                  <img id="brand-logo" :src="globalConfig.logo_small_screen" alt="brand logo" />
                </span>
                <span v-show="$vuetify.breakpoint.smAndUp" class="title">
                  <img id="brand-logo" :src="globalConfig.logo_login_page" alt="brand logo" />
                </span>
              </router-link>
            </v-flex>
            <v-flex v-if="onboardingPage && $ceolawPortal" class="header-flex" xs8>
              <a
                  v-if="this.$route.name == 'home'"
                  class="headers top-alignment"
                  href="javascript:document.getElementById('legal-service-steps').scrollIntoView(true);"
              >
                For Entrepreneurs
              </a>

              <router-link
                  v-else
                  :to="{ name: 'home', params: { id: 'legal-service-steps' } }"
                  class="headers top-alignment"
                  title="For Entrepreneurs"
              >
                For Entrepreneurs
              </router-link>

              <ForLegalDepartmentsLink />
              <ForCSuiteLink />

              <router-link :to="'/for-lawyers'" class="headers toolbar-title top-alignment" title="For Lawyers">
                For Lawyers
              </router-link>
              <AboutUsLink />
            </v-flex>
            <v-flex v-if="onboardingPage" class="action-flex" xs2>
              <router-link v-if="!loggedIn() && !currentPage" :to="'/sign-in'" class="header-login" title="Login">
                <span><img alt="avatar" class="login-avatar" height="792" src="../../images/avatar-red.svg" /></span>
                Login
              </router-link>
              <router-link
                  v-if="!loggedIn() && !currentPage && $ceolawPortal"
                  :to="'/sign-up'"
                  class="sign-up-btn"
                  title="Sign Up"
              >
                Sign Up
              </router-link>
              <div v-if="!currentPage && loggedIn()" class="md-toolbar-section-end">
                <div class="main-page-toolbar">
                  <div class="dropdown mobile-dropdown" style="position: initial">
                    <span><img alt="avatar" height="792" src="../../images/avatar-red.svg" width="612" /></span>
                    <div class="dropdown-content">
                      <router-link
                        v-if="loggedIn()"
                        :to="'/'"
                        title="Sign Out"
                        v-on:click.native="destroySession(globalConfig, $ceolawPortal)"
                      >
                        Log Out
                      </router-link>
                      <router-link v-if="loggedIn()" :to="'/my_account/dashboard'" title="My Account">
                        My Account
                      </router-link>
                      <router-link
                          v-if="loggedIn() && companies.length > 1 && selected_role == 'client'"
                          :to="'/my_account/account/change_company'"
                          title="View Another Company"
                      >
                        View Another Company
                      </router-link>
                      <router-link
                        v-if="loggedIn() && current_user.roles.length > 1 && !user.is_sole_practitioner"
                        :to="'/my_account/account/change_role'"
                        title="View Another Role"
                      >
                        View Another Role
                      </router-link>
                    </div>
                  </div>
                  <i class="material-icons menu-toggle" @click="toggle">menu</i>
                </div>
              </div>
              <div v-if="!currentPage && !loggedIn() && $ceolawPortal" class="md-toolbar-section-end hamburger">
                <div class="dropdown mobile-dropdown">
                  <i class="material-icons menu-toggle" @click="toggle">menu</i>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-toolbar>
        <v-toolbar v-if="insideAccount()" v-show="$vuetify.breakpoint.smAndUp" id="navbar-my-account" prominent>
          <v-spacer></v-spacer>
          <v-menu ref="topMenu" attach=".attach-menu-element" bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="dropdown" v-bind="attrs" v-on="on">
                <span>
                  {{ current_user.full_name }}

                  <template v-if="user.current_company_name && selected_role == 'client'">
                    {{ ' (' + user.current_company_name + ')' }}
                  </template>
                </span>
              </div>
            </template>
            <v-list class="attach-menu-element" dense>
              <v-list-item-title v-if="!loggedIn()">
                <router-link :to="'/sign-in'" title="Sign In">Log In</router-link>
              </v-list-item-title>
              <v-list-item-title v-if="loggedIn()">
                <router-link
                  :to="'/'"
                  title="Sign Out"
                  v-on:click.native="destroySession(globalConfig, $ceolawPortal)"
                >
                  Log Out
                </router-link>
              </v-list-item-title>

              <v-list-item-title v-if="loggedIn() && user.is_client">
                <router-link
                    v-if="user.no_company_onboarding"
                    :to="'/my_account/account/select_service'"
                    title="my-profile"
                >
                  Get Started
                </router-link>
                <router-link :to="'/my_account/account/profile'" title="my-profile">My Profile</router-link>
              </v-list-item-title>

              <v-list-item-title v-if="loggedIn() && !user.is_sole_practitioner && !user.is_client">
                <router-link :to="'/my_account/account/profile'" title="my-profile">My Profile</router-link>
              </v-list-item-title>

              <v-list-item-title v-if="loggedIn() && user.is_lawyer && selected_role != 'sole_practitioner'">
                <a href="https://outlook.office365.com/mail/inbox" target="_blank">My Inbox</a>
                <br />
              </v-list-item-title>

              <v-list-item-title v-if="loggedIn() && companies.length > 1 && selected_role == 'client'">
                <router-link :to="'/my_account/account/change_company'" title="View Another Company">
                  View Another Company
                </router-link>
              </v-list-item-title>
              <v-list-item-title v-if="loggedIn() && current_user.roles.length > 1 && !user.is_sole_practitioner">
                <router-link :to="'/my_account/account/change_role'" title="View Another Role">
                  View Another Role
                </router-link>
              </v-list-item-title>
            </v-list>
          </v-menu>
        </v-toolbar>

        <div v-bind:id="navbarMarginTop()">
          <router-view :key="routerKey" @changeRouterKey="updateRouterKey"></router-view>
        </div>

        <ApplicationFooter v-if="!insideAccount() && onboardingPage && $ceolawPortal" />
      </div>
    </div>
  </v-app>
</template>

<script>
  import ApplicationFooter from 'views/shared_components/application-footer.vue'
  import HamburgerMenu from 'views/shared_components/hamburger-menu.vue'
  import ForLegalDepartmentsLink from 'views/home/for-legal-departments-link.vue'
  import ForCSuiteLink from 'views/home/for-c-suite-link.vue'
  import AboutUsLink from 'views/home/about-us-link.vue'

  export default {
    components: {
      ApplicationFooter,
      HamburgerMenu,
      ForLegalDepartmentsLink,
      ForCSuiteLink,
      AboutUsLink,
    },
    data: function () {
      return {
        companies: [],
        selected_role: '',
        roles: [],
        content_click_option: false,
        user: {
          full_name: '',
          current_company_name: '',
          kyc_wizard_onboarding: {
            finished: true,
          },
        },
        current_user: {
          full_name: '',
          roles: [],
        },
        routerKey: 1,
        globalConfig: {},
        isLoaded: false
      }
    },
    provide() {
      return {
        loginInComponent: this.loginInComponent,
      }
    },
    watch: {
      $route: function() {
        // apply jump-to on page load
        setTimeout(() => {
          const query = this.$route.query
          if (query && query.jump) {
            document.getElementsByClassName(query.jump)[0].scrollIntoView()
          }
        }, 100)

        // apply picturefill on pageload
        setTimeout(() => {
          picturefill()
        }, 100)
      },
    },
    created() {
      this.fetchUser()
      this.fetchSiteSetting()
    },
    computed: {
      currentPage() {
        return this.$route.path === '/expertconsultation'
      },
      onboardingPage() {
        return this.$route.query.package === undefined && this.$route.query.token === undefined
      },
    },
    methods: {
      navigateToExternalLink() {
        window.location.href = this.globalConfig.url
      },
      loginInComponent() {
        if (this.user.full_name === '') this.fetchUser()
      },
      fixedNavbar() {
        return window.innerWidth <= 760 && this.$route.query.package == undefined
      },
      navbarMarginTop() {
        return !this.insideAccount() && this.fixedNavbar() ? 'navbar-margin-top' : null
      },
      updateRouterKey() {
        this.routerKey += 1
      },
      fetchSiteSetting() {
        this.isLoaded = false
        var that =  this
        this.$axios
          .get("/site_settings.json")
          .then(response => {
            Object.assign(that.globalConfig, response.data)
            that.isLoaded = true
          })
          .catch(error => {
            console.error("Error:", error)
            that.isLoaded = true
          })
      },
      fetchUser() {
        this.$axios.get('/my_account/user.json').then(response => {
          this.user = response.data
          this.current_user = response.data.current_user
          this.selected_role = response.data.selected_role_name

        this.roles = response.data.roles
        if (response.data.current_company_name) this.user.current_company_name = response.data.current_company_name
        if (response.data.companies) this.companies = response.data.companies
      }).catch(error => {
        if (error.response.status === 401 && this.$uri().path() === '/onboarding/bdr') {
          this.fetchOnboardingUser()
        }
      })
    },
    fetchOnboardingUser () {
      this.$axios.get(`/onboarding.json?${this.$uri().query()}`).then(response => {
        this.user.full_name = response.data.full_name
        this.user.kyc_wizard_onboarding.finished = response.data.kyc_wizard_onboarding.finished
        this.companies = response.data.companies
      })
    },
    toggle () {
      var menu = document.querySelector('.menu-container')
      menu.classList.toggle('isOpen')
    },
    destroySession (site_settings, portal) {
      this.$axios.delete('/users/sign_out').then(function (response) {
        if (portal) {
          document.location.href = '/'
        } else {
          document.location.href = site_settings.url
        }
      })
    },
    loggedIn () {
      return this.user.full_name !== ''
    },
    kycFinished () {
      var client = this.user.is_client
      var kyc_wizard_finished =
        (this.user.kyc_wizard_onboarding && this.user.kyc_wizard_onboarding.finished) ||
        !this.user.kyc_wizard_onboarding
      return client && kyc_wizard_finished
    },
    insideAccount () {
      return window.location.href.includes('my_account')
    },
    closeMenu (event) {
      var menu = document.querySelector('.menu-container')
      menu.classList.toggle('isOpen')
    },
  },
}
</script>
