<template>
    <v-layout row wrap>

      <v-flex xs12 :class="{ 'md7 lg8': $ceolawPortal }" order-sm1 order-md2 class="red-stripe">
        <router-link :to="'/'" title="CEO Law" v-if="$ceolawPortal">
            <img src="../../images/leaf.png" alt="CEO Law" v-show="$vuetify.breakpoint.smAndDown" class="ceolaw-logo"/>
        </router-link>
        <router-link :to="'/'" title="CEO Law" v-else>
          <img :src="this.$globalConfig.logo_small_screen" alt="CEO Law" class="ceolaw-logo"/>
        </router-link>
        <div class="show-md show-lg main-body wrapping-content">
          <v-layout row wrap>
            <v-flex md12 lg12 class="toWrapHamburger">
              <ul class="collapsed-ul links-top">
                <li v-if='!loggedIn()' class="li-bold headers">
                  <router-link :to="'/sign-in'" @click.native="closeMenu" title="Sign In">Log In</router-link>
                </li>
                <div v-if='loggedIn()'>
                  <li class="li-bold headers">
                    <router-link v-on:click.native="destroySession($globalConfig, $ceolawPortal)" :to="'/'" title="Sign Out">
                      Log Out
                    </router-link>
                  </li>
                  <li class="li-bold headers">
                    <router-link @click.native="closeMenu" :to="'/my_account/dashboard'" title="My Account">
                      My Account
                    </router-link>
                  </li>
                </div>
              </ul>
              <ul class="collapsed-ul links-top" v-if="$ceolawPortal">
                <li class="li-bold headers"><router-link @click.native="closeMenu" :to="'/about'" title="About Us">About Us</router-link></li>
              </ul>
              <ul class="collapsed-ul links-top" v-if="$ceolawPortal">
                <li class="li-bold headers"><router-link @click.native="closeMenu" :to="'/legal-department'" title="For Legal Department">For Legal Departments</router-link></li>
              </ul>
              <ul class="collapsed-ul links-top" v-if="$ceolawPortal">
                <li class="li-bold headers"><router-link @click.native="closeMenu" :to="'/c-suite'" title="For C-Suite">For C-Suite</router-link></li>
              </ul>
              <ul class="collapsed-ul links-top" v-show="$vuetify.breakpoint.mdAndDown" v-if="$ceolawPortal">
                <li class="li-bold headers"><router-link @click.native="closeMenu" :to="'/high-volume-document-review'" title="High Volume Document Review">High Volume Document Review</router-link></li>
              </ul>
              <ul class="collapsed-ul links-top" v-if="$ceolawPortal">
                <li class="li-bold headers"><router-link @click.native="closeMenu" :to="'/for-lawyers'" title="For Lawyers">For Lawyers</router-link></li>
              </ul>
              <ul class="collapsed-ul links-top" v-if="$ceolawPortal">
                <li class="li-bold headers" v-if="this.$route.name == 'home'"><a href="javascript:document.getElementById('legal-service-steps').scrollIntoView(true);" @click="closeMenu">For Entrepreneurs</a></li>
                <li v-else class="li-bold headers"><router-link @click.native="closeMenu" :to="{ name: 'home', params: { id: 'legal-service-steps' }}" title="For Entrepreneurs">For Entrepreneurs</router-link>
                </li>
              </ul>
              <ul class="collapsed-ul links-top" v-if="$ceolawPortal">
                <li class="li-bold headers"><router-link @click.native="closeMenu" :to="'/lawyers'" title="Our Lawyers">Our Lawyers</router-link></li>
              </ul>
              <ul class="collapsed-ul links-top" v-show="$vuetify.breakpoint.mdAndDown" v-if="$ceolawPortal">
                <li class="li-bold headers"><router-link @click.native="closeMenu" :to="'/public-company-clo'" title="CLO for Public Companies">CLO for Public Companies</router-link></li>
              </ul>
            </v-flex>
          </v-layout>
        </div>

        <div class="show-sm">
          <ul class="collapsed-ul">
            <li v-if='!loggedIn()'><router-link :to="'/sign-in'" @click.native="closeMenu" title="Sign In" class="menu-sign-in">Log In</router-link></li>
            <li v-if='loggedIn()'><router-link v-on:click.native="destroySession($globalConfig, $ceolawPortal)" :to="'/'" title="Sign Out" class="menu-sign-in">Log Out</router-link></li>
            <span v-if="$ceolawPortal">
              <li><router-link @click.native="closeMenu" :to="'/'" title="Home">Home</router-link></li>
            </span>
            <span v-else>
              <li><a :href="$globalConfig.url" title="Home">Home</a></li>
            </span>
            <li v-if='loggedIn()'><router-link @click.native="closeMenu" :to="'/my_account/dashboard'" title="My Account">My Account</router-link></li>
            <li v-if="$ceolawPortal"><router-link @click.native="closeMenu" :to="'/about'" title="About Us">About Us</router-link></li>
            <span v-if="$ceolawPortal">
              <li v-if="this.$route.name == 'home'"><a href="javascript:document.getElementById('legal-service-steps').scrollIntoView(true);" @click="closeMenu">For Entrepreneurs</a></li>
              <li v-else><router-link @click.native="closeMenu" :to="{ name: 'home', params: { id: 'legal-service-steps' }}" title="For Entrepreneurs">For Entrepreneurs</router-link>
              </li>
            </span>
            <li v-if="$ceolawPortal"><router-link @click.native="closeMenu" :to="'/legal-department'" title="For Legal Department">For Legal Departments</router-link></li>
            <li v-if="$ceolawPortal"><router-link @click.native="closeMenu" :to="'/high-volume-document-review'" title="High Volume Document Review">High Volume Document Review</router-link></li>
            <li v-if="$ceolawPortal"><router-link @click.native="closeMenu" :to="'/c-suite'" title="For C-Suite">For C-Suite</router-link></li>
            <li v-if="$ceolawPortal"><router-link @click.native="closeMenu" :to="'/public-company-clo'" title="CLO for Public Companies">CLO for Public Companies</router-link></li>
            <li v-if="$ceolawPortal"><router-link @click.native="closeMenu" :to="'/for-lawyers'" title="For Lawyers">For Lawyers</router-link></li>
            <li v-if="$ceolawPortal"><router-link @click.native="closeMenu" :to="'/lawyers'" title="Our Lawyers">Our Lawyers</router-link></li>
          </ul>

          <div class="menu-social-links" v-if="$ceolawPortal">
            <a @click="closeMenu" href="https://www.facebook.com/ceolawcanada/" title="Facebook" target="_blank" rel="noopener"><icon name="facebook" scale="2"></icon></a>
            <a @click="closeMenu" href="https://x.com/ceolawcanada" title="Twitter" target="_blank" rel="noopener"><icon name="twitter" scale="2"></icon></a>
            <a @click="closeMenu" href="https://www.linkedin.com/company/27210721/" title="LinkedIn" target="_blank" rel="noopener"><icon name="linkedin" scale="2"></icon></a>
            <a @click="closeMenu" href="https://www.youtube.com/channel/UC14BkLaSuRXWfuSqZ_O4wvQ/videos" title="YouTube" target="_blank" rel="noopener"><icon name="youtube" scale="2"></icon></a>
            <p>
              <em>Copyright &copy; CEO Law. All Rights Reserved.</em>
            </p>
          </div>
        </div>
      </v-flex>


      <v-flex xs12 md5 lg4 order-sm2 order-md1 class="black-stripe show-md show-lg" v-if="$ceolawPortal">
         <div class="main-body">
             <img src='../../images/logo-white.png' v-show="$vuetify.breakpoint.mdAndUp" class="menu-logo"/>
           <h3 id="menu-container-black-h3">
             	&#8220; CEO Law. Our name says it all - an acronym for Customers,
              Employees, Owners - it means we’re committed to happy Customers,
              Employees, and Owners, in that order! &#8221;
           </h3>
           <p>
             <b>Steve Monk</b>, CEO &amp; Founder at CEO Law
           </p>
           <div class="menu-social-links">
             <a @click="closeMenu" href="https://www.facebook.com/ceolawcanada/" title="Facebook" target="_blank" rel="noopener"><icon name="facebook" scale="2"></icon></a>
             <a @click="closeMenu" href="https://x.com/ceolawcanada" title="Twitter" target="_blank" rel="noopener"><icon name="twitter" scale="2"></icon></a>
             <a @click="closeMenu" href="https://www.linkedin.com/company/27210721/" title="LinkedIn" target="_blank" rel="noopener"><icon name="linkedin" scale="2"></icon></a>
             <a @click="closeMenu" href="https://www.youtube.com/channel/UC14BkLaSuRXWfuSqZ_O4wvQ/videos" title="YouTube" target="_blank" rel="noopener"><icon name="youtube" scale="2"></icon></a>
             <p>
               <em>Copyright &copy; CEO Law. All Rights Reserved.</em>
             </p>
           </div>

         </div>
       </v-flex>

    </v-layout>
</template>

<script>
  import 'vue-awesome/icons';
  import Icon from 'vue-awesome/components/Icon';

  export default {
    components: {
      Icon
    },
    methods: {
      loggedIn: function() {
        return JSON.parse(document.querySelector('#app').getAttribute('data-user')) !== null;
      },
      closeMenu(event) {
        var menu = document.querySelector(".menu-container");
        menu.classList.toggle('isOpen');
      },
      destroySession(site_settings, portal) {
        this.$axios.delete('/users/sign_out').then(function(response) {
          if (portal) {
            document.location.href ='/';
          }
          else {
            document.location.href = site_settings.url;
          }
        });
      }
    }
  };
</script>
