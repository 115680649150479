import axios from "axios" // TODO: check if we need it

const globalConfig = {}

axios
  .get("/site_settings.json")
  .then(response => {
    Object.assign(globalConfig, response.data)
  })
  .catch(error => {
    console.error("Error:", error)
  })

const ceolawPortal = /localhost|127\.0\.0\.1|ceolawcanada/.test(window.location.href)
export { globalConfig, ceolawPortal }
