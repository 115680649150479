// vendor
import "babel-polyfill" // for vuetify ie11/safari9 support
import "es6-promise/auto"
import "@fortawesome/fontawesome-free/js/all"
import Vue from "vue"
import Vuetify from "vuetify"
import router from "../../router/router.js"
import { ceolawPortal, globalConfig } from "./global_config.js"

// js libs
import Axios from "axios"
import URI from "urijs"

//components
import VueAnalytics from "vue-analytics"
import VueSignaturePad from "vue-signature-pad"
import vuescroll from "vuescroll"
import HomeIndex from "views/home/index.vue"

//styles
import "vue-material/dist/vue-material.min.css"
import "vuetify/dist/vuetify.min.css"
import "../../stylesheets/application.scss"

Vue.config.silent = true

Vue.use(vuescroll)
Vue.use(VueSignaturePad)

const colors = {
  primary: "#f5f5f5", //'#1976D2',
  secondary: "#272e37", //'#424242',
  accent: "#f5f5f5", //'#82B1FF',
  error: "#f5f5f5", //'#FF5252',
  info: "#f5f5f5", //'#2196F3',
  success: "#f5f5f5", //'#4CAF50',
  warning: "#f5f5f5", //'#FFC107'
}

// customize vuetify default colors
Vue.use(Vuetify, {
  theme: colors,
})

Vue.use(VueAnalytics, {
  id: document.querySelector("#app").getAttribute("data-google-analytics-id"),
  router,
})

document.addEventListener("DOMContentLoaded", function (event) {
  new Vue({
    el: "#app",
    vuetify: new Vuetify({
      icons: {
        iconfont: "md",
      },
    }),
    router,
    components: {
      HomeIndex,
    },
    mounted: function () {
      if (typeof window.orientation !== "undefined") {
        $(".mobile-dropdown").on("click", function (e) {
          $(e).toggleClass("open")
          e.preventDefault()
        })
      }
    },
  })
})

document.createElement("picture")

Axios.defaults.headers.common = {
  "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
}

Vue.prototype.$axios = Axios
const axios = Axios

Vue.prototype.$uri = URI
const uri = URI

Vue.prototype.$globalConfig = globalConfig
Vue.prototype.$ceolawPortal = ceolawPortal

axios.interceptors.response.use(
  response => response,
  error => {
    let excludedRoutes = ["/my_account/user.json", "/users/sign_in.json"]

    if (error.response.status === 401 && !excludedRoutes.includes(error.config.url)) {
      window.location.href = "/sign-in"
    }
    if (error.response.status === 404) {
      window.location.href = "/sign-in"
    }
    return Promise.reject(error)
  },
)
